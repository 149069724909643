import React, { useState, useEffect } from 'react';
import authFetch from '../services/authFetch'; // Certifique-se de que authFetch está configurado corretamente
import '../styles/PainelPage.css';  // Importa o CSS específico para a página

function PainelPage() {
  const [error, setError] = useState(null); // Estado para armazenar erros
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento
  const [resultado, setResultado] = useState(null); // Estado para armazenar o resultado da consulta
  const [count, setCount] = useState(0); // Total de resultados
  const [pageSize] = useState(10); // Itens por página
  const [currentPage, setCurrentPage] = useState(1); // Página atual
  const [currentPageUrl, setCurrentPageUrl] = useState('https://panel.zepesquisa.com.br/api/api-usage/'); // URL da página atual
  const [nextPageUrl, setNextPageUrl] = useState(null); // URL da próxima página
  const [previousPageUrl, setPreviousPageUrl] = useState(null); // URL da página anterior

  useEffect(() => {
    const fetchConsultas = async (url) => {
      try {
        const response = await authFetch(url);
        if (!response.ok) {
          throw new Error('Erro ao buscar consultas');
        }

        const data = await response.json();
        setResultado(data.results); // Armazena os resultados da consulta
        setCount(data.count); // Armazena o número total de resultados
        setNextPageUrl(data.next); // Define a URL da próxima página
        setPreviousPageUrl(data.previous); // Define a URL da página anterior
        setLoading(false); // Define que o carregamento foi concluído
      } catch (err) {
        setError(err.message);
        setLoading(false); // Define que o carregamento foi concluído mesmo com erro
      }
    };

    fetchConsultas(currentPageUrl);
  }, [currentPageUrl]);

  const formatDateTime = (dateTime) => {
    return new Date(dateTime).toLocaleString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    });
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  const handleNextPage = () => {
    if (nextPageUrl) {
      setCurrentPageUrl(nextPageUrl);
      setCurrentPage((prev) => prev + 1); // Incrementa a página atual
      setLoading(true);
    }
  };

  const handlePreviousPage = () => {
    if (previousPageUrl) {
      setCurrentPageUrl(previousPageUrl);
      setCurrentPage((prev) => prev - 1); // Decrementa a página atual
      setLoading(true);
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro: {error}</div>;
  }

  const totalPages = Math.ceil(count / pageSize); // Calcula o número total de páginas

  return (
    <div className="painel-container">
      <h2>Painel de Consultas</h2>

      {/* Exibe o resultado da consulta */}
      {resultado && (
        <div className="resultado-consulta">
          <h3>Resultado:</h3>
          <table className="consulta-tabela">
            <thead>
              <tr>
                <th>Link</th>
                <th>Data/Hora</th>
                <th>Valor</th>
              </tr>
            </thead>
            <tbody>
              {resultado.map((item, index) => (
                <tr key={index}>
                  <td>{item.link}</td>
                  <td>{formatDateTime(item.usage_at)}</td>
                  <td>{formatCurrency(item.value)}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Controles de paginação */}
          <div className="pagination-controls">
            <button
              onClick={handlePreviousPage}
              disabled={!previousPageUrl}
              className="pagination-btn"
            >
              Página Anterior
            </button>
            <span>
              Página {currentPage} de {totalPages > 0 ? totalPages : 1}
            </span>
            <button
              onClick={handleNextPage}
              disabled={!nextPageUrl}
              className="pagination-btn"
            >
              Próxima Página
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PainelPage;
