import React, { useState, useEffect } from 'react';
import authFetch from '../services/authFetch'; // Certifique-se de que authFetch está configurado corretamente
import '../styles/ConsultarPage.css';  // Importa o CSS específico para a página

function ConsultarPage() {
  const [consultas, setConsultas] = useState([]); // Estado para armazenar os métodos disponíveis
  const [selectedConsulta, setSelectedConsulta] = useState(''); // Estado para armazenar a consulta selecionada
  const [query, setQuery] = useState(''); // Estado para armazenar o valor que o usuário deseja consultar (query)
  const [error, setError] = useState(null); // Estado para armazenar erros
  const [loading, setLoading] = useState(true); // Estado para controlar o carregamento
  const [resultado, setResultado] = useState(null); // Estado para armazenar o resultado da consulta
  const [submitting, setSubmitting] = useState(false); // Estado para controlar o texto do botão

  useEffect(() => {
    const fetchConsultas = async () => {
      try {
        const response = await authFetch('https://panel.zepesquisa.com.br/api/list-methods/');
        if (!response.ok) {
          throw new Error('Erro ao buscar consultas');
        }
        const data = await response.json();
        setConsultas(data.available_endpoints || []); // Armazena os métodos disponíveis
        setLoading(false); // Define que o carregamento foi concluído
      } catch (err) {
        setError(err.message);
        setLoading(false); // Define que o carregamento foi concluído mesmo com erro
      }
    };

    fetchConsultas();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitting(true); // Ativa o estado de "Carregando..."

    // Cria a URL com base na consulta selecionada
    const url = `https://panel.zepesquisa.com.br/api/${selectedConsulta}/`;

    try {
      // Faz a requisição POST com o valor no corpo como { query: valor }
      const response = await authFetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ query }), // Envia "query" no corpo da requisição
      });

      if (!response.ok) {
        throw new Error('Erro ao realizar a consulta');
      }

      const result = await response.json();
      setResultado(result); // Armazena o resultado da consulta
    } catch (err) {
      setError(err.message);
    } finally {
      setSubmitting(false); // Desativa o estado de "Carregando..."
    }
  };

  // Função para renderizar o resultado de forma amigável
  const renderResult = (data) => {
    if (Array.isArray(data)) {
      return (
        <ul className="result-array">
          {data.map((item, index) => (
            <li key={index} className="result-array-item">
              {renderResult(item)}
            </li>
          ))}
        </ul>
      );
    } else if (typeof data === 'object' && data !== null) {
      return (
        <table className="result-table">
          <tbody>
            {Object.entries(data).map(([key, value]) => (
              <tr key={key}>
                <td className="result-key">{key.replace(/_/g, ' ')}</td>
                <td className="result-value">{renderResult(value)}</td>
              </tr>
            ))}
          </tbody>
        </table>
      );
    } else if (data === null) {
      return <span className="result-primitive">N/A</span>;
    } else if (typeof data === 'boolean') {
      return <span className="result-primitive">{data ? 'Sim' : 'Não'}</span>;
    } else {
      return <span className="result-primitive">{String(data)}</span>;
    }
  };

  if (loading) {
    return <div>Carregando...</div>;
  }

  if (error) {
    return <div>Erro: {error}</div>;
  }

  return (
    <div className="consultar-container">
      <h2>Consultar</h2>

      {/* Exibe o formulário somente se o resultado ainda não foi exibido */}
      {!resultado && (
        <form onSubmit={handleSubmit} className="recarga-form">
          <label htmlFor="consulta">Escolha a consulta:</label>
          <select
            id="consulta"
            value={selectedConsulta}
            onChange={(e) => setSelectedConsulta(e.target.value)}
            required
          >
            <option value="" disabled>Selecione uma consulta</option>
            {consultas.length > 0 ? (
              consultas.map((consulta, index) => (
                <option key={index} value={consulta}>
                  {consulta.replace(/_/g, ' ')} {/* Substitui underscores por espaços */}
                </option>
              ))
            ) : (
              <option>Nenhuma consulta disponível</option>
            )}
          </select>

          <label htmlFor="query">Digite o valor para a consulta:</label>
          <input
            type="text"
            id="query"
            placeholder="Ex: CPF, CNPJ, Nome, etc..."
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            required
          />

          <button type="submit" className="generate-pix-btn" disabled={submitting}>
            {submitting ? 'Carregando...' : 'Consultar'}
          </button>
        </form>
      )}

      {/* Exibe o resultado da consulta */}
      {resultado && (
        <div className="resultado-consulta">
          <h3>Resultado da Consulta:</h3>
          {renderResult(resultado)}
        </div>
      )}
    </div>
  );
}

export default ConsultarPage;
