import { jwtDecode } from 'jwt-decode';

// Função para verificar se o token está expirado
const isTokenExpired = (token) => {
  if (!token) return true;

  const { exp } = jwtDecode(token);
  return Date.now() >= exp * 1000; // Verifica se o token expirou
};

// Função para renovar o token de acesso usando o refresh token
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem('refresh');
  if (!refreshToken) {
    throw new Error('Nenhum refresh token disponível');
  }

  const response = await fetch('https://panel.zepesquisa.com.br/api/token/refresh/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ refresh: refreshToken }),
  });

  if (!response.ok) {
    throw new Error('Falha ao renovar o token de acesso');
  }

  const data = await response.json();
  localStorage.setItem('access', data.access); // Armazena o novo token de acesso
  return data.access;
};

// Função para fazer requisições autenticadas
const authFetch = async (url, options = {}) => {
  let accessToken = localStorage.getItem('access');

  // Verifica se o token de acesso está expirado
  if (isTokenExpired(accessToken)) {
    try {
      accessToken = await refreshAccessToken(); // Renova o token se expirado
    } catch (error) {
      console.error('Erro ao renovar o token: ', error);
      // Redireciona para a página de login se a renovação falhar
      window.location.href = '/login';
      return;
    }
  }

  // Adiciona o token de acesso ao cabeçalho Authorization
  const headers = {
    'Authorization': `Bearer ${accessToken}`,
    'Content-Type': 'application/json',
    ...options.headers,  // Mescla os headers passados com os padrões
  };

  // Faz a requisição
  const response = await fetch(url, {
    ...options,
    headers,
  });

  // Verifica se a resposta é 401 (não autorizado) e tenta renovar o token
  if (response.status === 401) {
    console.log('Token expirado. Tentando renovar...');
    try {
      accessToken = await refreshAccessToken();
      return authFetch(url, options); // Tenta novamente com o token renovado
    } catch (error) {
      console.error('Erro ao renovar o token: ', error);
      window.location.href = '/login';
    }
  }

  return response;
};

export default authFetch;
